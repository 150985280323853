<template>
  <v-card tile :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: `${page_route}.detalle`,
              params: { id: rs_id },
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <RsData :rs_id="rs_id" />
        </v-col>
        <v-col cols="12" v-if="send_folio_alert">
          <v-alert type="warning" class="text-center" dense>
            El folio de envío no ha sido registrado, por lo cual no se puede
            terminar este registro de manera automática
          </v-alert>
        </v-col>
        <v-col v-if="rs_services.length > 0" cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="'SERVICIOS (' + rs_services.length + ')'"
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" class="text-center" width="30px" />
                      <th v-text="'Folio'" />
                      <th v-text="'Búsqueda de'" />
                      <th v-text="'Tipo'" />
                      <th v-text="'Médico | Proveedor'" />
                      <th
                        v-if="login.role_id != 7 && login.role_id != 8"
                        v-text="'Convenio'"
                        width="50px"
                        class="text-center"
                      />
                      <th
                        v-if="login.role_id != 7 && login.role_id != 8"
                        v-text="'Aceptar Carta'"
                        width="50px"
                        class="text-center"
                      />
                      <th
                        v-if="login.role_id != 7 && login.role_id != 8"
                        v-text="'Valoración prestaciones'"
                        width="50px"
                        class="text-center"
                      />
                      <th
                        v-if="login.role_id != 7 && login.role_id != 8"
                        v-text="'Surtida'"
                        width="170px"
                        class="text-center"
                      />
                      <th v-text="'Carta generada'" />
                      <th v-text="'Monto deducible'" />
                      <th v-text="'Coaseguro %'" />
                      <th v-text="'Confirmación'" />
                      <th v-text="'Ingreso Folio'" />
                      <th style="min-width: 250px" class="text-right" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(v, i) in rs_services"
                      :key="i"
                      :class="
                        v.active
                          ? v.letter_generated_cancelled
                            ? 'red lighten-4'
                            : v.refused
                            ? 'warning lighten-4'
                            : ''
                          : 'red'
                      "
                    >
                      <td class="font-weight-bold text-center" v-text="i + 1" />
                      <td v-text="v.folio" />
                      <td
                        v-text="
                          v.rs_service_type_id === 1
                            ? 'CONSULTA'
                            : v.rs_service_type_id === 2
                            ? 'PROVEEDOR'
                            : 'REMBOLSO'
                        "
                      />
                      <td
                        v-text="
                          v.rs_service_type_id === 1
                            ? v.specialty_type.specialty_type
                            : v.rs_service_type_id === 2
                            ? v.provider_type.provider_type
                            : '-'
                        "
                      />
                      <td>
                        <span
                          :class="
                            v.doctor_id || v.provider_id
                              ? ''
                              : 'orange--text text--darken-3'
                          "
                        >
                          {{
                            v.rs_service_type_id === 3
                              ? "-"
                              : v.doctor_id
                              ? "IDM " +
                                v.doctor_id +
                                " | " +
                                v.doctor.name +
                                " " +
                                v.doctor.first_surname +
                                " " +
                                v.doctor.second_surname
                              : v.provider_id
                              ? "IDP " +
                                v.provider_id +
                                " " +
                                v.provider.trade_name
                              : "PENDIENTE"
                          }}
                        </span>
                        <v-tooltip
                          v-if="v.doctor_id && login.permissions.doctors.read"
                          right
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="info"
                              :to="{
                                name: 'doctors.view',
                                params: { id: v.doctor_id },
                              }"
                            >
                              <v-icon small> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          Ver
                        </v-tooltip>
                        <v-tooltip
                          v-if="
                            v.provider_id && login.permissions.providers.read
                          "
                          right
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="info"
                              :to="{
                                name: 'providers.view',
                                params: { id: v.provider_id },
                              }"
                            >
                              <v-icon small> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          Ver
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              v-if="v.doctor_id && v.doctor.valid == 0"
                              small
                              color="orange"
                            >
                              mdi-alert
                            </v-icon>
                          </template>
                          <span
                            v-text="
                              'Pendiente de revisión, no se puede generar carta'
                            "
                          />
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              v-if="v.doctor_id && v.doctor.valid != true"
                              small
                              color="orange"
                            >
                              mdi-alert
                            </v-icon>
                          </template>
                          <span v-text="'Médico sin validación o duplicado'" />
                        </v-tooltip>
                      </td>
                      <td
                        v-if="login.role_id != 7 && login.role_id != 8"
                        class="text-center"
                      >
                        <v-icon v-if="v.agreement" color="green darken-2">
                          mdi-check
                        </v-icon>
                        <span v-else v-text="'-'" />
                      </td>
                      <td
                        v-if="login.role_id != 7 && login.role_id != 8"
                        class="text-center"
                      >
                        <span
                          :class="
                            v.rs_service_type_id === 1 &&
                            v.doctor_id &&
                            (v.rha_letter_doctor_rs_services ||
                              v.consulting_room_id) &&
                            v.confirm_consultation &&
                            v.response_confirm_consultation === null
                              ? 'orange--text text--darken-3'
                              : v.response_confirm_consultation
                              ? ''
                              : 'red--text'
                          "
                          v-text="
                            v.rs_service_type_id === 2 ||
                            (v.rs_service_type_id === 1 && !v.doctor_id)
                              ? '-'
                              : v.confirm_consultation === 0
                              ? '-'
                              : v.response_confirm_consultation === null
                              ? 'PENDIENTE'
                              : v.response_confirm_consultation === 1
                              ? `SI (${v.confirm_consultation_by.email})`
                              : 'NO'
                          "
                        />
                      </td>
                      <td
                        v-if="login.role_id != 7 && login.role_id != 8"
                        class="text-center"
                      >
                        <span
                          :class="
                            v.benefits_revisions
                              ? 'orange--text text--darken-3'
                              : ''
                          "
                          v-text="
                            v.benefits_revisions ? v.benefits_revisions : '-'
                          "
                        />
                      </td>
                      <td
                        v-if="login.role_id != 7 && login.role_id != 8"
                        class="text-center"
                      >
                        <span
                          :class="
                            v.pharmacy_dispatch !== false &&
                            v.pharmacy_dispatch === null
                              ? 'orange--text text--darken-3'
                              : ''
                          "
                          v-text="
                            v.pharmacy_dispatch === false
                              ? '-'
                              : v.pharmacy_dispatch
                              ? v.pharmacy_dispatch
                              : 'PENDIENTE'
                          "
                        />
                      </td>
                      <td>
                        {{
                          v.letter_generated
                            ? v.letter_generated +
                              " | " +
                              v.letter_generated_by.email
                            : "-"
                        }}
                      </td>
                      <td>
                        {{
                          v.letter_generated
                            ? numberFormat(v.deducible_amount)
                            : "-"
                        }}
                      </td>
                      <td>
                        {{ v.letter_generated ? v.coinsurance : "-" }}
                      </td>
                      <td>
                        <div
                          v-if="v.letter_generated && rs && rs.rs_type_id == 1"
                        >
                          <!-- <div v-if="v.confirm_date"> -->
                          <small
                            :class="v.confirm ? '' : 'red--text'"
                            v-text="v.confirm_date"
                          />
                          <!-- <br />
                            <v-tooltip v-if="login.role_id == 1" left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  x-small
                                  color="error"
                                  @click.prevent="confirmRemove(v)"
                                >
                                  <v-icon> mdi-close</v-icon>
                                </v-btn>
                              </template>
                              <span v-text="'Eliminar confirmación'" />
                            </v-tooltip>
                          </div>
                          <v-tooltip v-else left>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                small
                                color="warning"
                                @click.prevent="
                                  login.permissions.rs_service_confirmation.read
                                    ? confirmDlg(v)
                                    : false
                                "
                              >
                                <v-icon> mdi-alert</v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Pendiente de confirmar'" />
                          </v-tooltip> -->
                        </div>
                      </td>
                      <td>
                        <span
                          class="text-description"
                          v-text="v.rha ? v.rha.folio : ''"
                        />
                        <v-tooltip
                          v-if="v.rha && login.permissions.rhas.read"
                          left
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              color="info"
                              x-small
                              icon
                              :to="{
                                name: 'admission_request.detalle',
                                params: { id: v.rha.id },
                              }"
                            >
                              <v-icon small> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Detalle'" />
                        </v-tooltip>
                      </td>
                      <td style="min-width: 220px" class="text-right">
                        <div v-if="rs && rs.active">
                          <v-tooltip v-if="v.rs_service_type_id != 3" left>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="info"
                                @click.prevent="formView(v)"
                              >
                                <v-icon> mdi-file-eye </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver detalle'" />
                          </v-tooltip>
                          <v-tooltip v-if="v.survey_base64" left>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                dark
                                x-small
                                color="teal"
                                @click.prevent="
                                  fileDownload(
                                    v.survey_base64,
                                    v.survey_ext,
                                    'encuesta_serv'
                                  )
                                "
                              >
                                <v-icon> mdi-file-table </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver encuesta'" />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              login.role_id != 7 &&
                              login.role_id != 8 &&
                              login.permissions.rs_letters.create &&
                              (!discharged || rs_type_id == 5) &&
                              v.active &&
                              !v.refused &&
                              !v.letter_generated &&
                              !v.letter_generated_cancelled &&
                              ((v.doctor_id &&
                                (v.rha_letter_doctor_rs_services ||
                                  v.consulting_room_id) &&
                                (medical_revision || rs_type_id == 5) &&
                                v.doctor.valid &&
                                (v.confirm_consultation === 0 ||
                                  v.response_confirm_consultation === 1)) ||
                                v.provider_id ||
                                v.rs_service_type_id === 3) &&
                              v.benefits_revisions === 0
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="warning"
                                @click="letterGenerateView(v)"
                                :disabled="
                                  login.role_id != 1 &&
                                  rs &&
                                  rs.insured &&
                                  rs.insured.expired
                                "
                              >
                                <v-icon> mdi-file </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Generar carta'" />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              v.letter_generated &&
                              !v.letter_generated_cancelled &&
                              v.rs_service_type_id == 3
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="pink darken-2"
                                dark
                                @click="RefoundLetterView(rs_id)"
                              >
                                <v-icon> mdi-file-certificate </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver carta reembolso'" />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              v.letter_generated &&
                              !v.letter_generated_cancelled &&
                              v.rs_service_type_id != 3
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="success"
                                @click="letterView(v.id)"
                              >
                                <v-icon> mdi-file-certificate </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver carta'" />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              rs.email &&
                              v.letter_generated &&
                              !v.letter_generated_cancelled &&
                              v.rs_service_type_id != 3
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="warning"
                                @click="sendForEmail(v)"
                              >
                                <v-icon> mdi-email-fast </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Re-enviar carta'" />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              !discharged &&
                              v.active &&
                              v.letter_generated &&
                              !v.letter_generated_cancelled &&
                              login.permissions.rss_letters_regenerate.update
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                :color="
                                  v.rs_service_bill &&
                                  v.rs_service_bill.insurance_reception_date !=
                                    null
                                    ? ''
                                    : 'warning'
                                "
                                @click="
                                  v.rs_service_bill &&
                                  v.rs_service_bill.insurance_reception_date !=
                                    null
                                    ? false
                                    : letterGenerateView(v)
                                "
                                :disabled="
                                  login.role_id != 1 &&
                                  rs &&
                                  rs.insured &&
                                  rs.insured.expired
                                "
                              >
                                <v-icon> mdi-file-refresh </v-icon>
                              </v-btn>
                            </template>
                            <span
                              v-text="
                                v.rs_service_bill &&
                                v.rs_service_bill.insurance_reception_date
                                  ? 'No se puede regenerar carta, información enviada a la aseguradora (' +
                                    v.rs_service_bill.insurance_reception_date +
                                    ') con folio ' +
                                    v.rs_service_bill.folio
                                  : 'Regenerar carta'
                              "
                            />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              v.active &&
                              v.letter_generated &&
                              !v.letter_generated_cancelled &&
                              login.permissions.rs_letters.delete
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="error"
                                @click="rsServiceletterGeneratedCancelled(v)"
                              >
                                <v-icon> mdi-book-cancel </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Eliminar carta'" />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              login.role_id == 1 &&
                              v.active &&
                              !discharged &&
                              v.provider_id &&
                              v.letter_generated &&
                              !v.letter_generated_cancelled
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="teal"
                                dark
                                :to="{
                                  name: 'servicios.cartas_documentacion',
                                  params: { rs_service_id: v.id },
                                }"
                              >
                                <v-icon> mdi-file-multiple </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Documentación'" />
                          </v-tooltip>
                          <!-- <v-tooltip
                            v-if="v.rs_service_type_id && !v.rs_service_bill"
                            left
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                fab
                                x-small
                                :color="v.doctor_email_type_id ? '' : 'indigo'"
                                dark
                                @click.prevent="doctorEmailDlg(v)"
                              >
                                <v-icon> mdi-cube-send </v-icon>
                              </v-btn>
                            </template>
                            Enviar C. electronico a médico
                          </v-tooltip> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="rs_services_medical.length > 0" cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="
                    'SERVICIOS CON SEGUIMIENTO MÉDICO (' +
                    rs_services_medical.length +
                    ')'
                  "
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" class="text-center" width="30px" />
                      <th v-text="'Folio'" />
                      <th v-text="'Búsqueda de'" />
                      <th v-text="'Tipo'" />
                      <th v-text="'Médico | Proveedor'" />
                      <th
                        v-if="login.role_id != 7 && login.role_id != 8"
                        v-text="'Convenio'"
                        width="50px"
                        class="text-center"
                      />
                      <th
                        v-if="login.role_id != 7 && login.role_id != 8"
                        v-text="'Aceptar Carta'"
                        width="50px"
                        class="text-center"
                      />
                      <th
                        v-if="login.role_id != 7 && login.role_id != 8"
                        v-text="'Valoración prestaciones'"
                        width="50px"
                        class="text-center"
                      />
                      <th
                        v-if="login.role_id != 7 && login.role_id != 8"
                        v-text="'Surtida'"
                        width="170px"
                        class="text-center"
                      />
                      <th v-text="'Carta generada'" />
                      <th v-text="'Monto deducible'" />
                      <th v-text="'Coaseguro %'" />
                      <th v-text="'Confirmación'" />
                      <th v-text="'Ingreso Folio'" />
                      <th
                        v-text="''"
                        style="min-width: 220px"
                        class="text-right"
                      />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(v, i) in rs_services_medical"
                      :key="i"
                      :class="
                        v.active
                          ? v.letter_generated_cancelled
                            ? 'red lighten-4'
                            : v.refused
                            ? 'warning lighten-4'
                            : ''
                          : 'red'
                      "
                    >
                      <td class="font-weight-bold text-center" v-text="i + 1" />
                      <td>
                        <div>{{ v.folio }}</div>
                        <small v-if="!v.active">
                          Últ. act. {{ v.updated_at }} |
                          {{ v.updated_by.email }}
                        </small>
                      </td>
                      <td
                        v-text="
                          v.rs_service_type_id === 1
                            ? 'CONSULTA'
                            : v.rs_service_type_id === 2
                            ? 'PROVEEDOR'
                            : 'REMBOLSO'
                        "
                      />
                      <td
                        v-text="
                          v.rs_service_type_id === 1
                            ? v.specialty_type.specialty_type
                            : v.rs_service_type_id === 2
                            ? v.provider_type.provider_type
                            : '-'
                        "
                      />
                      <td>
                        <span
                          :class="
                            v.doctor_id || v.provider_id
                              ? ''
                              : 'orange--text text--darken-3'
                          "
                        >
                          {{
                            v.rs_service_type_id === 3
                              ? "-"
                              : v.doctor_id
                              ? "IDM " +
                                v.doctor_id +
                                " | " +
                                v.doctor.name +
                                " " +
                                v.doctor.first_surname +
                                " " +
                                v.doctor.second_surname
                              : v.provider_id
                              ? "IDP " +
                                v.provider_id +
                                " " +
                                v.provider.trade_name
                              : "PENDIENTE"
                          }}
                        </span>
                        <v-tooltip
                          v-if="v.doctor_id && login.permissions.doctors.read"
                          right
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="info"
                              :to="{
                                name: 'doctors.view',
                                params: { id: v.doctor_id },
                              }"
                            >
                              <v-icon small> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          Ver
                        </v-tooltip>
                        <v-tooltip
                          v-if="
                            v.provider_id && login.permissions.providers.read
                          "
                          right
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="info"
                              :to="{
                                name: 'providers.view',
                                params: { id: v.provider_id },
                              }"
                            >
                              <v-icon small> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          Ver
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              v-if="v.doctor_id && v.doctor.valid == 0"
                              small
                              color="orange"
                            >
                              mdi-alert
                            </v-icon>
                          </template>
                          <span
                            v-text="
                              'Pendiente de revisión, no se puede generar carta'
                            "
                          />
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              v-if="v.doctor_id && v.doctor.valid != true"
                              small
                              color="orange"
                            >
                              mdi-alert
                            </v-icon>
                          </template>
                          <span v-text="'Médico sin validación o duplicado'" />
                        </v-tooltip>
                      </td>
                      <td
                        v-if="login.role_id != 7 && login.role_id != 8"
                        class="text-center"
                      >
                        <v-icon v-if="v.agreement" color="green darken-2">
                          mdi-check
                        </v-icon>
                        <span v-else v-text="'-'" />
                      </td>
                      <td
                        v-if="login.role_id != 7 && login.role_id != 8"
                        class="text-center"
                      >
                        <span
                          :class="
                            v.rs_service_type_id === 1 &&
                            v.doctor_id &&
                            (v.rha_letter_doctor_rs_services ||
                              v.consulting_room_id) &&
                            v.confirm_consultation &&
                            v.response_confirm_consultation === null
                              ? 'orange--text text--darken-3'
                              : v.response_confirm_consultation
                              ? ''
                              : 'red--text'
                          "
                          v-text="
                            v.rs_service_type_id === 2 ||
                            (v.rs_service_type_id === 1 && !v.doctor_id)
                              ? '-'
                              : v.confirm_consultation === 0
                              ? '-'
                              : v.response_confirm_consultation === null
                              ? 'PENDIENTE'
                              : v.response_confirm_consultation === 1
                              ? `SI (${v.confirm_consultation_by.email})`
                              : 'NO'
                          "
                        />
                      </td>
                      <td
                        v-if="login.role_id != 7 && login.role_id != 8"
                        class="text-center"
                      >
                        <span
                          :class="
                            v.benefits_revisions
                              ? 'orange--text text--darken-3'
                              : ''
                          "
                          v-text="
                            v.benefits_revisions ? v.benefits_revisions : '-'
                          "
                        />
                      </td>
                      <td
                        v-if="login.role_id != 7 && login.role_id != 8"
                        class="text-center"
                      >
                        <span
                          :class="
                            v.pharmacy_dispatch !== false &&
                            v.pharmacy_dispatch === null
                              ? 'orange--text text--darken-3'
                              : ''
                          "
                          v-text="
                            v.pharmacy_dispatch === false
                              ? '-'
                              : v.pharmacy_dispatch
                              ? v.pharmacy_dispatch
                              : 'PENDIENTE'
                          "
                        />
                      </td>
                      <td>
                        {{
                          v.letter_generated
                            ? v.letter_generated +
                              " | " +
                              v.letter_generated_by.email
                            : "-"
                        }}
                      </td>
                      <td>
                        {{
                          v.letter_generated
                            ? numberFormat(v.deducible_amount)
                            : "-"
                        }}
                      </td>
                      <td>
                        {{ v.letter_generated ? v.coinsurance : "-" }}
                      </td>
                      <td>
                        <div
                          v-if="v.letter_generated && v.rs_service_type_id == 1"
                        >
                          <div v-if="v.confirm_date">
                            <small
                              :class="v.confirm ? '' : 'red--text'"
                              v-text="v.confirm_date"
                            />
                            <br />
                            <span
                              v-if="v.confirm_manual"
                              v-text="v.confirm_user.email"
                            />
                            <br />
                            <small v-if="v.confirm_manual">
                              <b>MANUAL</b>
                            </small>
                            <br />
                            <v-tooltip v-if="login.role_id == 1" left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  x-small
                                  color="error"
                                  @click.prevent="confirmRemove(v)"
                                >
                                  <v-icon> mdi-close</v-icon>
                                </v-btn>
                              </template>
                              <span v-text="'Eliminar confirmación'" />
                            </v-tooltip>
                          </div>
                          <v-tooltip v-else left>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                small
                                color="warning"
                                @click.prevent="
                                  login.permissions.rs_service_confirmation.read
                                    ? confirmDlg(v)
                                    : false
                                "
                              >
                                <v-icon> mdi-alert</v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Pendiente de confirmar'" />
                          </v-tooltip>
                        </div>
                      </td>
                      <td>
                        <span
                          class="text-description"
                          v-text="v.rha ? v.rha.folio : ''"
                        />
                        <v-tooltip
                          v-if="v.rha && login.permissions.rhas.read"
                          left
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              color="info"
                              x-small
                              icon
                              :to="{
                                name: 'admission_request.detalle',
                                params: { id: v.rha.id },
                              }"
                            >
                              <v-icon small> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Detalle'" />
                        </v-tooltip>
                      </td>
                      <td style="min-width: 250px" class="text-right">
                        <div v-if="rs && rs.active">
                          <v-tooltip v-if="v.rs_service_type_id != 3" left>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="info"
                                @click.prevent="formView(v)"
                              >
                                <v-icon> mdi-file-eye </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver detalle'" />
                          </v-tooltip>
                          <v-tooltip v-if="v.survey_base64" left>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                dark
                                x-small
                                color="teal"
                                @click.prevent="
                                  fileDownload(
                                    v.survey_base64,
                                    v.survey_ext,
                                    'encuesta_serv'
                                  )
                                "
                              >
                                <v-icon> mdi-file-table </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver encuesta'" />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              login.role_id != 7 &&
                              login.role_id != 8 &&
                              login.permissions.rs_letters.create &&
                              (!discharged || rs_type_id == 5) &&
                              v.active &&
                              !v.refused &&
                              !v.letter_generated &&
                              !v.letter_generated_cancelled &&
                              ((v.doctor_id &&
                                (v.rha_letter_doctor_rs_services ||
                                  v.consulting_room_id) &&
                                (medical_revision || rs_type_id == 5) &&
                                v.doctor.valid &&
                                (v.confirm_consultation === 0 ||
                                  v.response_confirm_consultation === 1)) ||
                                v.provider_id ||
                                v.rs_service_type_id === 3) &&
                              v.benefits_revisions === 0
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="warning"
                                @click="letterGenerateView(v)"
                                :disabled="
                                  login.role_id != 1 &&
                                  rs &&
                                  rs.insured &&
                                  rs.insured.expired
                                "
                              >
                                <v-icon> mdi-file </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Generar carta'" />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              v.letter_generated &&
                              !v.letter_generated_cancelled &&
                              v.rs_service_type_id == 3
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="pink darken-2"
                                dark
                                @click="RefoundLetterView(rs_id)"
                              >
                                <v-icon> mdi-file-certificate </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver carta reembolso'" />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              v.letter_generated &&
                              !v.letter_generated_cancelled &&
                              v.rs_service_type_id != 3
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="success"
                                @click="letterView(v.id)"
                              >
                                <v-icon> mdi-file-certificate </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver carta'" />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              rs.email &&
                              v.letter_generated &&
                              !v.letter_generated_cancelled &&
                              v.rs_service_type_id != 3
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="warning"
                                @click="sendForEmail(v)"
                              >
                                <v-icon> mdi-email-fast </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Re-enviar carta'" />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              !discharged &&
                              v.active &&
                              v.letter_generated &&
                              !v.letter_generated_cancelled &&
                              login.permissions.rss_letters_regenerate.update
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                :color="
                                  v.rs_service_bill &&
                                  v.rs_service_bill.insurance_reception_date !=
                                    null
                                    ? ''
                                    : 'warning'
                                "
                                @click="
                                  v.rs_service_bill &&
                                  v.rs_service_bill.insurance_reception_date !=
                                    null
                                    ? false
                                    : letterGenerateView(v)
                                "
                                :disabled="
                                  login.role_id != 1 &&
                                  rs &&
                                  rs.insured &&
                                  rs.insured.expired
                                "
                              >
                                <v-icon> mdi-file-refresh </v-icon>
                              </v-btn>
                            </template>
                            <span
                              v-text="
                                v.rs_service_bill &&
                                v.rs_service_bill.insurance_reception_date
                                  ? 'No se puede regenerar carta, información enviada a la aseguradora (' +
                                    v.rs_service_bill.insurance_reception_date +
                                    ') con folio ' +
                                    v.rs_service_bill.folio
                                  : 'Regenerar carta'
                              "
                            />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              v.active &&
                              v.letter_generated &&
                              !v.letter_generated_cancelled &&
                              login.permissions.rs_letters.delete
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="error"
                                @click="rsServiceletterGeneratedCancelled(v)"
                              >
                                <v-icon> mdi-book-cancel </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Eliminar carta'" />
                          </v-tooltip>
                          <v-tooltip
                            left
                            v-if="
                              login.role_id == 1 &&
                              v.provider_id &&
                              v.active &&
                              !discharged &&
                              v.active &&
                              v.letter_generated &&
                              !v.letter_generated_cancelled
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="teal"
                                dark
                                :to="{
                                  name: 'servicios.cartas_documentacion',
                                  params: { rs_service_id: v.id },
                                }"
                              >
                                <v-icon> mdi-file-multiple </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Documentación'" />
                          </v-tooltip>
                          <!-- <v-tooltip
                            v-if="v.rs_service_type_id && !v.rs_service_bill"
                            left
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                fab
                                x-small
                                :color="v.doctor_email_type_id ? '' : 'indigo'"
                                dark
                                @click.prevent="doctorEmailDlg(v)"
                              >
                                <v-icon> mdi-cube-send </v-icon>
                              </v-btn>
                            </template>
                            Enviar C. electronico a médico
                          </v-tooltip> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- Dialog VIEW start -->
    <v-dialog v-model="view_dialog" scrollable persistent max-width="1200px">
      <v-card
        tile
        :loading="view_dialog_loading"
        :disabled="view_dialog_loading"
      >
        <v-toolbar dark>
          <v-toolbar-title v-text="'Detalle'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="formViewClose()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="view_dialog">
          <v-row class="mt-3">
            <v-col cols="12" v-if="data.letter_generated_cancelled">
              <v-alert type="error" class="text-center" dense>
                SERVICIO CANCELADO | {{ data.updated_by.email }} |
                {{ data.updated_at }}
              </v-alert>
            </v-col>
            <v-col cols="12" v-if="data.refused">
              <v-alert type="warning" class="text-center" dense>
                SERVICIO RECHAZADO
              </v-alert>
            </v-col>

            <v-col cols="12" v-if="data.doctor_id">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'CONSULTA'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-btn
                      v-if="
                        !discharged &&
                        data.active &&
                        login.role_id != 7 &&
                        login.role_id != 8 &&
                        !data.refused &&
                        !data.letter_generated
                      "
                      color="info"
                      dark
                      @click="searcherDialogView(true)"
                      class="ml-1"
                    >
                      BUSCAR OTRO
                    </v-btn>
                    <v-btn
                      v-if="
                        !discharged &&
                        data.active &&
                        login.role_id != 7 &&
                        login.role_id != 8 &&
                        !data.refused &&
                        data.rs_service_type_id === 1 &&
                        data.confirm_consultation &&
                        !data.confirm_consultation_at
                      "
                      color="warning"
                      dark
                      @click="consultationConfirmDialog"
                      class="ml-1"
                    >
                      ACEPTAR CARTA
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Médico"
                        :value="`IDM ${data.doctor.id} | ${data.doctor.name} ${data.doctor.first_surname} ${data.doctor.second_surname}`"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        v-if="login.role_id != 7"
                        label="Contacto"
                        :value="`${
                          data.doctor.phone
                            ? `TEL. ${data.doctor.phone} / `
                            : ''
                        }${
                          data.doctor.cell_phone
                            ? `CEL. ${data.doctor.cell_phone} / `
                            : ''
                        }${data.doctor.email ? data.doctor.email : ''}`"
                      />
                    </v-col>
                    <!-- <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Horario"
                        :value="`${data.consulting_room.start} - ${data.consulting_room.end}`"
                      />
                    </v-col> -->
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        v-if="login.role_id != 7"
                        label="Monto"
                        :value="numberFormat(data.benefits[0].amount)"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Dirección"
                        :value="`${
                          data.consulting_room && data.consulting_room.street
                            ? data.consulting_room.street
                            : ''
                        }${
                          data.consulting_room &&
                          data.consulting_room.external_number
                            ? ` ${data.consulting_room.external_number}`
                            : ''
                        }${
                          data.consulting_room &&
                          data.consulting_room.internal_number
                            ? `, INT. ${data.consulting_room.internal_number}`
                            : ''
                        }${
                          data.consulting_room && data.consulting_room.suburb
                            ? `, COL. ${data.consulting_room.suburb}`
                            : ''
                        }`"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Municipio"
                        :value="
                          data.consulting_room
                            ? data.consulting_room.municipality_name
                            : '-'
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Estado"
                        :value="
                          data.consulting_room
                            ? data.consulting_room.entity_name
                            : ''
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Código postal"
                        :value="
                          data.consulting_room ? data.consulting_room.zip : ''
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        :label="'Especialidad'"
                        :value="
                          data.doctor_specialty
                            ? data.doctor_specialty.name
                            : ''
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Cédula"
                        :value="
                          data.doctor_specialty
                            ? data.doctor_specialty.license
                            : ''
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <span
                        class="text-caption font-weight-bold"
                        v-text="'Confirm. Cita'"
                      />
                      <br />
                      <v-icon
                        small
                        :color="
                          data.doctor.confirm_consultation ? 'success' : ''
                        "
                      >
                        mdi-{{
                          data.doctor.confirm_consultation ? "check" : "close"
                        }}
                      </v-icon>
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <span
                        class="text-caption font-weight-bold"
                        v-text="'A. A. Médicamentos'"
                      />
                      <br />
                      <v-icon
                        small
                        :color="data.doctor.medication_auth ? 'success' : ''"
                      >
                        mdi-{{
                          data.doctor.medication_auth ? "check" : "close"
                        }}
                      </v-icon>
                    </v-col>
                    <v-col cols="12">
                      <b>
                        <small class="green--text">
                          Últ. actualización: {{ data.doctor.updated_at }}
                        </small>
                      </b>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <br />
              <v-card
                tile
                v-if="
                  data.confirm_consultation && data.confirm_consultation_by_id
                "
              >
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="'CONFIRMACIÓN DE CONSULTA'"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Nombre de con quien se hablo"
                        :value="data.confirm_consultation_name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Fecha"
                        :value="data.confirm_consultation_date"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Confirmación de monto"
                        :value="data.confirm_consultation_amount"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Confirmo"
                        :value="data.confirm_consultation_by.email"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Fecha sistema"
                        :value="data.confirm_consultation_at"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" v-if="data.provider">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'PROVEEDOR'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-btn
                      v-if="
                        !discharged &&
                        data.active &&
                        login.role_id != 7 &&
                        login.role_id != 8 &&
                        !data.refused &&
                        !data.letter_generated
                      "
                      color="info"
                      dark
                      @click="searcherDialogView(true)"
                      class="ml-1"
                    >
                      BUSCAR OTRO
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Tipo de proveedor"
                        :value="data.provider.provider_type.provider_type"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData label="Nombre" :value="data.provider.name" />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Nombre de comercial"
                        :value="data.provider.trade_name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Dirección"
                        :value="`${
                          data.provider.street ? data.provider.street : ''
                        }${
                          data.provider.external_number
                            ? ` ${data.provider.external_number}`
                            : ''
                        }${
                          data.provider.internal_number
                            ? `, INT. ${data.provider.internal_number}`
                            : ''
                        }${
                          data.provider.suburb
                            ? `, COL. ${data.provider.suburb}`
                            : ''
                        }`"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Municipio"
                        :value="data.provider.municipality"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData label="Estado" :value="data.provider.entity" />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Código postal"
                        :value="data.provider.zip"
                      />
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="text-caption font-weight-bold"
                        v-text="'Prestaciones'"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-row dense>
                        <v-col cols="12" v-if="data.benefits.length > 0">
                          <v-simple-table dense>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th v-text="'#'" />
                                  <th v-text="'Código'" />
                                  <th v-text="'Descripción'" />
                                  <th v-text="'Cobertura'" />
                                  <th v-text="'Monto'" />
                                  <th v-text="''" />
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(vb, k) in data.benefits"
                                  :key="k"
                                  v-if="vb.active"
                                >
                                  <td v-text="`${k + 1}`" />
                                  <td v-text="vb.code" />
                                  <td v-text="vb.description" />
                                  <td
                                    :class="
                                      vb.revision
                                        ? 'orange--text text--darken-3'
                                        : ''
                                    "
                                  >
                                    {{
                                      vb.coverage_type
                                        ? vb.coverage_type
                                        : "N/A"
                                    }}

                                    <v-btn
                                      v-if="
                                        !discharged &&
                                        data.active &&
                                        login.role_id != 7 &&
                                        login.role_id != 8 &&
                                        login.permissions.rs_benefit_revisions
                                          .update &&
                                        !data.refused &&
                                        vb.revision &&
                                        vb.response_revision === null
                                      "
                                      class="ml-1"
                                      x-small
                                      color="warning"
                                      dark
                                      @click.prevent="benefitRevisionView(vb)"
                                    >
                                      <v-icon
                                        class="mr-1"
                                        x-small
                                        v-text="'mdi-file-alert'"
                                      />
                                      Validar
                                    </v-btn>
                                    <v-tooltip
                                      v-if="
                                        vb.revision &&
                                        vb.response_revision !== null
                                      "
                                      right
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-on="on"
                                          small
                                          v-text="
                                            vb.response_revision
                                              ? 'mdi-check-circle'
                                              : 'mdi-close-circle'
                                          "
                                        />
                                      </template>
                                      <span
                                        v-text="
                                          vb.response_revision
                                            ? 'Aprobado'
                                            : 'Rechazado'
                                        "
                                      />
                                    </v-tooltip>
                                  </td>
                                  <td v-text="numberFormat(vb.amount)" />
                                  <td></td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>

                          <div class="text-center">
                            <small v-text="'Monto Total'" />
                            <p
                              class="text-h6"
                              v-text="numberFormat(data.benefits_amount)"
                            />
                          </div>
                        </v-col>
                        <v-col cols="12" v-else>
                          <p class="text-caption">
                            Ningúna prestación ha sido agregada
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              v-if="data.agreement && !data.provider && !data.doctor"
            >
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="
                        `CONVENIO (${
                          data.rs_service_type_id === 1
                            ? 'CONSULTA'
                            : 'PROVEEDOR'
                        })`
                      "
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-btn
                      v-if="!discharged && data.active"
                      color="warning"
                      dark
                      @click="searcherDialogView(true)"
                    >
                      BUSCAR
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-alert type="warning" dense>
                        <v-row align="center">
                          <v-col class="text-center">
                            No se encontró
                            {{
                              data.rs_service_type_id === 1
                                ? "médico"
                                : "proveedor"
                            }}
                            para est{{
                              data.rs_service_type_id === 1
                                ? "a consulta"
                                : "e servicio"
                            }}
                          </v-col>
                        </v-row>
                      </v-alert>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      md="3"
                      v-if="data.rs_service_type_id === 1"
                    >
                      <ViewData
                        label="Tipo de especialidad "
                        :value="data.specialty_type.specialty_type"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      md="3"
                      v-if="data.rs_service_type_id === 2"
                    >
                      <ViewData
                        label="Tipo de proveedor"
                        :value="data.provider_type.provider_type"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Municipio"
                        :value="
                          data.municipality_id === null
                            ? 'TODOS'
                            : data.municipality.municipality
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData label="Estado" :value="data.entity.entity" />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData label="Código postal" :value="data.zip" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" v-if="letter_generated">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'CONDICIONES'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-switch
                      class="mt-0 pt-3"
                      :label="'Reflejar deducible y coaseguro en carta'"
                      v-model="data.letter_complete"
                      dense
                    />
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="Monto de deducible"
                        v-model="data.deducible_amount"
                        type="number"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="Coaseguro %"
                        v-model="data.coinsurance"
                        type="number"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="Tope de Deducible y Coaseguro"
                        v-model="data.coinsurance_amount"
                        type="number"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="Tope máximo"
                        v-model="data.assured_amount"
                        type="number"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="12">
                      <v-textarea
                        rows="3"
                        label="Observación"
                        v-model="data.caption"
                        dense
                      />
                    </v-col>
                    <v-col v-if="login.role_id == 1" cols="12" xs="12" md="3">
                      <v-text-field
                        label="Fecha Carta"
                        v-model="data.letter_generated_date"
                        type="date"
                        dense
                      />
                    </v-col>
                    <v-col v-if="login.role_id == 1" cols="12" xs="12" md="3">
                      <v-text-field
                        label="Hora Carta"
                        v-model="data.letter_generated_time"
                        type="time"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              v-if="
                letter_generated &&
                data.pharmacy &&
                !isEmptyObject(data.pharmacy)
              "
            >
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="'CONDICIONES FARMACIA'"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-alert type="success" class="text-center" dense>
                        Se generara una carta con servicio de farmacia ya que el
                        médico cuenta con "Autorizacion automática de
                        médicamentos"
                      </v-alert>
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="Monto de deducible"
                        v-model="data.pharmacy.deducible_amount"
                        type="number"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="Coaseguro %"
                        v-model="data.pharmacy.coinsurance"
                        type="number"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="Tope de Deducible y Coaseguro"
                        v-model="data.pharmacy.coinsurance_amount"
                        type="number"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="Tope máximo"
                        v-model="data.pharmacy.assured_amount"
                        type="number"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="12">
                      <v-text-field
                        label="Observación"
                        v-model="data.pharmacy.caption"
                        type="text"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-btn
                v-if="
                  !discharged &&
                  data.active &&
                  login.role_id != 7 &&
                  login.role_id != 8 &&
                  login.permissions.rs_refuseds.update &&
                  !data.refused &&
                  !data.letter_generated_cancelled &&
                  !letter_generated
                "
                x-small
                color="warning"
                @click.prevent="serviceRefused"
              >
                <v-icon class="mr-1" x-small v-text="'mdi-book-remove'" />
                Rechazar
              </v-btn>

              <v-btn
                v-if="
                  data.active &&
                  login.role_id != 7 &&
                  login.role_id != 8 &&
                  login.permissions.rs_letters.create &&
                  letter_generated &&
                  !data.refused &&
                  (!data.letter_generated ||
                    (data.letter_generated &&
                      login.permissions.rss_letters_regenerate.update)) &&
                  ((data.doctor_id &&
                    (data.confirm_consultation == 0 ||
                      data.response_confirm_consultation === 1)) ||
                    data.provider_id ||
                    data.rs_service_type_id == 3) &&
                  data.benefits_revisions === 0
                "
                small
                block
                color="warning"
                @click.prevent="letterGenerate(data)"
              >
                Generar Carta
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="searcher_dialog"
      scrollable
      persistent
      max-width="1800px"
    >
      <v-card
        tile
        :loading="searcher_dialog_loading"
        :disabled="searcher_dialog_loading"
      >
        <v-toolbar dark>
          <v-toolbar-title v-text="'Servicio'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="searcherDialogViewClose()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="searcher_dialog">
          <v-row class="mt-3">
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'BÚSQUEDA'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items></v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="6">
                      <v-select
                        label="Búsqueda de"
                        v-model="service_search.type"
                        :items="service_types"
                        item-value="id"
                        :item-text="(i) => i.name"
                        dense
                        item-disabled="item_disabled"
                        disabled
                      />
                    </v-col>
                    <v-col
                      v-if="service_search.type === null"
                      cols="12"
                      xs="12"
                      md="6"
                    />
                    <v-col
                      cols="12"
                      xs="12"
                      md="6"
                      v-if="service_search.type === 1"
                    >
                      <v-autocomplete
                        label="Tipo de especialidad"
                        v-model="service_search.specialty_type_id"
                        :items="specialty_types"
                        item-value="id"
                        :item-text="(i) => i.specialty_type"
                        :loading="specialty_types_loading"
                        dense
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      md="6"
                      v-if="service_search.type === 2"
                    >
                      <v-autocomplete
                        label="Tipo de proveedor"
                        v-model="service_search.provider_type_id"
                        :items="provider_types"
                        item-value="id"
                        :item-text="(i) => i.provider_type"
                        :loading="provider_types_loading"
                        dense
                        item-disabled="item_disabled"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <v-autocomplete
                        label="Estado"
                        v-model="service_search.entity_id"
                        :items="entities"
                        item-value="id"
                        :item-text="(i) => i.entity"
                        :loading="entities_loading"
                        v-on:change="getMunicipalities()"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <v-autocomplete
                        label="Municipio"
                        v-model="service_search.municipality_id"
                        :items="municipalities"
                        item-value="id"
                        :item-text="(i) => i.municipality"
                        :loading="municipalities_loading"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <v-text-field
                        label="C.P."
                        v-model="service_search.zip"
                        maxlength="5"
                        type="text"
                        dense
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        service_search.type &&
                        (service_search.specialty_type_id ||
                          service_search.provider_type_id) &&
                        service_search.entity_id
                      "
                    >
                      <v-btn
                        block
                        color="info"
                        small
                        @click.prevent="serviceSearch"
                      >
                        <v-icon left v-text="'mdi-database-search'" />
                        BUSCAR
                        {{
                          service_search.type === 1 ? "CONSULTA" : "PROVEEDOR"
                        }}
                      </v-btn>
                    </v-col>
                    <v-col cols="12" v-else>
                      <v-alert type="info" dense>
                        <v-row align="center">
                          <v-col class="text-center">
                            Se requiere mínimo los campos de "Búsqueda de",
                            "Tipo de proveedor o especialidad" y "Estado" para
                            poder realizar una búsqueda
                          </v-col>
                        </v-row>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'RESULTADO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense v-if="services.length > 0">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th v-text="'#'" />
                              <th
                                v-text="
                                  'ID' + (service_search.type == 1 ? 'M' : 'P')
                                "
                              />
                              <th v-text="'Nombre'" />
                              <th v-text="'Estado'" />
                              <th v-text="'Municipio'" />
                              <th v-text="'Dirección'" />
                              <th v-text="'C.P.'" />
                              <th
                                v-if="
                                  service_search.type == 1 &&
                                  login.role_id != 7 &&
                                  login.role_id != 8
                                "
                                v-text="'Monto'"
                              />
                              <th
                                v-if="service_search.type == 1"
                                v-text="'Confirm. Cita'"
                              />
                              <th
                                v-if="service_search.type == 1"
                                v-text="'A. A. Médicamentos'"
                              />
                              <th
                                v-if="service_search.type == 1"
                                v-text="'Pend. cobro'"
                              />
                              <th
                                v-if="service_search.type == 1"
                                v-text="'Pend. pago'"
                              />
                              <th v-text="''" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v, i) in services" :key="i">
                              <td class="font-weight-bold" v-text="i + 1" />
                              <td>
                                {{ v.id }}
                                <span v-if="service_search.type == 1">
                                  <v-icon
                                    :color="
                                      v.valid != null
                                        ? v.valid
                                          ? 'success'
                                          : 'error'
                                        : ''
                                    "
                                  >
                                    mdi-{{
                                      v.valid != null
                                        ? v.valid
                                          ? "check-"
                                          : "alert-"
                                        : ""
                                    }}decagram
                                  </v-icon>
                                </span>
                              </td>
                              <td>
                                {{ v.name }}
                                <v-icon
                                  v-if="v.auto_letter_generated"
                                  small
                                  right
                                  color="success"
                                >
                                  mdi-window-shutter-auto
                                </v-icon>
                              </td>
                              <td v-text="v.entity_name" />
                              <td v-text="v.municipality_name" />
                              <td>
                                {{
                                  (v.street ? v.street : "") +
                                  " " +
                                  (v.external_number ? v.external_number : "") +
                                  (v.internal_number
                                    ? " INT. " + v.internal_number
                                    : "") +
                                  (v.suburb ? ", COL. " + v.suburb : "")
                                }}
                              </td>
                              <td v-text="v.zip" />
                              <td
                                v-if="
                                  service_search.type == 1 &&
                                  login.role_id != 7 &&
                                  login.role_id != 8
                                "
                                v-text="numberFormat(v.amount)"
                              />
                              <td v-if="service_search.type == 1">
                                <v-icon
                                  small
                                  :color="
                                    v.confirm_consultation ? 'success' : ''
                                  "
                                >
                                  mdi-{{
                                    v.confirm_consultation ? "check" : "close"
                                  }}
                                </v-icon>
                              </td>
                              <td v-if="service_search.type == 1">
                                <v-icon
                                  small
                                  :color="v.medication_auth ? 'success' : ''"
                                >
                                  mdi-{{
                                    v.medication_auth ? "check" : "close"
                                  }}
                                </v-icon>
                              </td>
                              <td v-if="service_search.type == 1">
                                <div class="text-center">
                                  <div v-if="v.rs_services_pending.length > 0">
                                    {{ v.rs_services_pending.length }}
                                    <v-tooltip left>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          v-on="on"
                                          text
                                          small
                                          color="warning"
                                          @click="
                                            rsServicesPendingDlg(
                                              v.rs_services_pending,
                                              1,
                                              v.id,
                                              v.name
                                            )
                                          "
                                        >
                                          <v-icon small> mdi-file-eye </v-icon>
                                        </v-btn>
                                      </template>
                                      <span v-text="'Ver registros'" />
                                    </v-tooltip>
                                  </div>
                                  <span v-else v-text="'-'" />
                                </div>
                              </td>
                              <td v-if="service_search.type == 1">
                                <div class="text-center">
                                  <div
                                    v-if="v.rs_services_pending_paid.length > 0"
                                  >
                                    {{ v.rs_services_pending_paid.length }}
                                    <v-tooltip left>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          v-on="on"
                                          text
                                          small
                                          color="pink"
                                          dark
                                          @click="
                                            rsServicesPendingDlg(
                                              v.rs_services_pending_paid,
                                              2,
                                              v.id,
                                              v.name
                                            )
                                          "
                                        >
                                          <v-icon small> mdi-file-eye </v-icon>
                                        </v-btn>
                                      </template>
                                      <span v-text="'Ver registros'" />
                                    </v-tooltip>
                                  </div>
                                  <span v-else v-text="'-'" />
                                </div>
                              </td>
                              <td width="50px">
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      icon
                                      small
                                      color="success"
                                      @click="serviceData(v)"
                                    >
                                      <v-icon small v-text="'mdi-send'" />
                                    </v-btn>
                                  </template>
                                  <span v-text="'Seleccionar'" />
                                </v-tooltip>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <span v-else>
                        {{
                          services_loading
                            ? "cargando..."
                            : "Sin resultados de busqueda"
                        }}
                      </span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rs_services_pending_dlg"
      scrollable
      persistent
      max-width="1000"
    >
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title>
            PEND. {{ rs_services_pending_type == 1 ? "COBRO" : "PAGO" }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rs_services_pending_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="rs_services_pending">
          <v-row class="pt-3">
            <v-col cols="12">
              <ViewData
                label="Médico"
                :value="
                  'IDM ' +
                  rs_services_pending_doctor_id +
                  ' | ' +
                  rs_services_pending_doctor_name
                "
              />
            </v-col>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Folio'" />
                      <th v-text="'F. creación'" />
                      <th v-text="'F. carta'" />
                      <th v-text="'Monto asegurado'" />
                      <th v-text="'Doc. ID'" />
                      <th v-text="'Promesa de pago'" />
                      <th v-text="'Total a pagar'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in rs_services_pending" :key="i">
                      <td
                        class="text-caption font-weight-bold"
                        v-text="item.key + 1"
                      />
                      <td>
                        <div class="text-center">
                          <span v-text="item.folio" />
                          <br />
                          <v-tooltip
                            v-if="login.permissions.rs_letters.read"
                            left
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                small
                                class="ml-1"
                                color="warning"
                                :to="{
                                  name: 'servicios.cartas',
                                  params: { id: item.rs_id },
                                }"
                              >
                                <v-icon small> mdi-eye </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver detalle'" />
                          </v-tooltip>
                        </div>
                      </td>
                      <td v-text="item.created_at" />
                      <td v-text="item.letter_generated" />
                      <td v-text="numberFormat(item.assured_amount)" />
                      <td>
                        <div class="text-center">
                          <span v-text="item.doc_id" />
                          <br />
                          <v-tooltip
                            v-if="
                              item.rs_service_bill_id &&
                              login.permissions.rs_service_bills.read
                            "
                            left
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                small
                                class="ml-1"
                                color="pink"
                                dark
                                :to="{
                                  name: 'facturacion_servicios.detalle',
                                  params: { id: item.rs_service_bill_id },
                                }"
                              >
                                <v-icon small> mdi-eye </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver detalle'" />
                          </v-tooltip>
                        </div>
                      </td>
                      <td v-text="item.pay_date" />
                      <td
                        v-text="
                          item.pay_amount ? numberFormat(item.pay_amount) : ''
                        "
                      />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="benefit_revision_dialog"
      scrollable
      persistent
      max-width="800px"
    >
      <v-card
        tile
        :loading="benefit_revision_dialog_loading"
        :disabled="benefit_revision_dialog_loading"
      >
        <v-toolbar dark>
          <v-toolbar-title v-text="'Validación de prestación'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="benefitRevisionViewClose()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="benefit_revision_dialog">
          <v-row class="mt-3">
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'DOCUMENTACIÓN'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items></v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_submit_revision"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12">
                        <b
                          v-text="
                            `${benefit_revision.code} | ${benefit_revision.description}`
                          "
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        v-for="(v, i) in benefit_revision.documents"
                        :key="i"
                      >
                        <v-row v-if="v.active">
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              :label="`Descripción (${i + 1})*`"
                              v-model="v.description"
                              :rules="rules.required"
                              type="text"
                              dense
                            />
                          </v-col>
                          <v-col cols="12" xs="12" md="5">
                            <v-file-input
                              label="Archivo digital*"
                              v-model="v.file"
                              accept="image/*,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
                              :rules="rules.required"
                              show-size
                              dense
                            />
                          </v-col>
                          <v-col cols="12" xs="12" md="1" class="text-right">
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  small
                                  color="error"
                                  @click.prevent="fileDelete(i)"
                                >
                                  <v-icon medium v-text="'mdi-file-remove'" />
                                </v-btn>
                              </template>
                              <span v-text="`Eliminar documento (${i + 1})`" />
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              fab
                              x-small
                              color="warning"
                              :loading="loading"
                              @click.prevent="fileAdd"
                            >
                              <v-icon v-text="'mdi-file-plus'" />
                            </v-btn>
                          </template>
                          <span v-text="'Agregar documento'" />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <v-btn
                color="error"
                block
                @click.prevent="benefitRevision(false)"
              >
                RECHAZAR
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <v-btn
                color="success"
                block
                @click.prevent="benefitRevision(true)"
              >
                VALIDAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="consultation_confirm_dialog"
      scrollable
      persistent
      max-width="800px"
    >
      <v-card
        tile
        :loading="consultation_confirm_dialog_loading"
        :disabled="consultation_confirm_dialog_loading"
      >
        <v-toolbar dark>
          <v-toolbar-title>Aceptar carta</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="consultation_confirm_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="consultation_confirm_dialog">
          <v-form
            v-on:submit.prevent
            ref="form_consultation_confirm"
            lazy-validation
          >
            <v-row class="mt-3">
              <v-col cols="12" xs="12" md="12">
                <v-text-field
                  label="Nombre de con quien se hablo*"
                  v-model="confirm_consultation_name"
                  maxlength="75"
                  type="text"
                  dense
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" xs="12" md="4">
                <DatePicker
                  label="Fecha*"
                  :model.sync="confirm_consultation_date"
                  clean
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" xs="12" md="4">
                <v-text-field
                  v-model="confirm_consultation_time"
                  label="Hora*"
                  type="time"
                  dense
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" xs="12" md="4">
                <v-text-field
                  v-model="confirm_consultation_amount"
                  label="Monto*"
                  type="number"
                  dense
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" class="text-right">
                <div v-if="data.doctor.rs_services_pending.length > 0">
                  PEND. COBRO ({{ data.doctor.rs_services_pending.length }})
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        text
                        small
                        color="warning"
                        @click="
                          rsServicesPendingDlg(
                            data.doctor.rs_services_pending,
                            1,
                            data.doctor.id,
                            data.doctor.name
                          )
                        "
                      >
                        <v-icon small> mdi-file-eye </v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Ver registros'" />
                  </v-tooltip>
                </div>
                <div v-if="data.doctor.rs_services_pending_paid.length > 0">
                  PEND. PAGO ({{ data.doctor.rs_services_pending_paid.length }})
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        text
                        small
                        color="pink"
                        dark
                        @click="
                          rsServicesPendingDlg(
                            data.doctor.rs_services_pending_paid,
                            2,
                            data.doctor.id,
                            data.doctor.name
                          )
                        "
                      >
                        <v-icon small> mdi-file-eye </v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Ver registros'" />
                  </v-tooltip>
                </div>
              </v-col>
              <v-col cols="12" xs="12" md="6">
                <v-btn
                  color="error"
                  block
                  @click.prevent="consultationConfirm(false)"
                >
                  NO
                </v-btn>
              </v-col>
              <v-col cols="12" xs="12" md="6">
                <v-btn
                  color="success"
                  block
                  @click.prevent="consultationConfirm(true)"
                >
                  SI
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirm_dlg" scrollable persistent max-width="900px">
      <v-card tile :disabled="confirm_dlg_ldg" :loading="confirm_dlg_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> CONFIRMACIÓN </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="confirm_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="confirm">
          <v-row>
            <v-col cols="12" class="mt-3" />
            <v-col cols="12">
              <v-row dense>
                <v-col v-if="rha" cols="12">
                  <RhaWithRsService :rha="rha" />
                </v-col>
                <v-col cols="12" xs="6" md="9">
                  URL:
                  <small id="link" v-text="confirm.link" />
                </v-col>
                <v-col cols="12" xs="6" md="3" class="text-right">
                  <v-btn block text color="success" @click="copy()">
                    Copiar URL
                  </v-btn>
                  <!-- <v-btn
                    v-if="
                      login.permissions.rs_service_bills_confirmation.update
                    "
                    text
                    color="warning"
                    @click="confirmManual()"
                  >
                    Manual
                  </v-btn> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog VIEW end -->

    <v-dialog v-model="doctor_email_dlg" scrollable persistent max-width="800">
      <v-card
        tile
        :disabled="doctor_email_dlg_ldg"
        :loading="doctor_email_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Enviar C. Electrónico a Médico </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctor_email_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="doctor_email">
          <v-form v-on:submit.prevent ref="doctor_email_form" lazy-validation>
            <v-row dense>
              <v-col cols="12" class="mt-3" />
              <v-col cols="12">
                <v-text-field
                  label="C. electrónico"
                  v-model="doctor_email.email"
                  :rules="rules.email"
                  type="text"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Opción"
                  v-model="doctor_email.doctor_email_type_id"
                  :items="doctor_email_types"
                  item-value="id"
                  :item-text="(itm) => itm.name"
                  dense
                  :rules="rules.required"
                  @change="getDoctorEmailTxt"
                />
              </v-col>
              <v-col cols="12">
                <ViewData label="Previsualización" :value="doctor_email.txt" />
              </v-col>
              <v-col cols="12" class="pt-5">
                <v-btn
                  x-small
                  block
                  color="indigo"
                  dark
                  @click.prevent="doctorEmail"
                >
                  Enviar
                  <v-icon x-small right> mdi-send </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_PDF,
  dateTimeToFile,
  base64ToArrayBuffer,
  dateTimeNow,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import RsData from "../../components/RsData.vue";
import DatePicker from "../../components/DatePicker.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    RsData,
    DatePicker,
    FaqDlg,
  },
  data() {
    return {
      page_route: "servicios",
      api: "rss/administratives",
      login: this.$store.getters.getLogin,
      rs_id: this.$route.params.id,
      rs: null,
      loading: true,
      rules: rules(),
      rs_services: [],
      rs_services_medical: [],
      data: null,
      medical_revision: false,
      view_dialog: false,
      documents: [],
      documents_loading: true,
      url_pdf: URL_PDF,
      letter_generated: false,
      view_dialog_loading: false,
      send_folio_alert: false,
      searcher_dialog: false,
      searcher_dialog_loading: false,
      service_search: null,
      entities_loading: [],
      entities_loading: true,
      specialty_types: [],
      specialty_types_loading: true,
      service_types: [],
      provider_types: [],
      provider_types_loading: true,
      municipalities: [],
      municipalities_loading: false,
      services: [],
      services_loading: false,
      medical_revision: true,
      searcher_agreement: false,
      benefit_revision: null,
      benefit_revision_dialog: false,
      benefit_revision_dialog_loading: false,
      consultation_confirm_dialog: false,
      consultation_confirm_dialog_loading: false,
      confirm_consultation_name: null,
      confirm_consultation_date: null,
      confirm_consultation_time: null,
      confirm_consultation_amount: null,
      rs_services: [],
      rs_services_medical: [],
      discharged: null,
      rs_type_id: null,
      confirm: null,
      confirm_dlg: false,
      confirm_dlg_ldg: false,
      rha: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
      rs_services_pending: [],
      rs_services_pending_type: null,
      rs_services_pending_doctor_id: null,
      rs_services_pending_doctor_name: null,
      rs_services_pending_dlg: false,
      doctor_email_types: [
        {
          id: 1,
          name: "OPCIÓN 1 | CARGAR DATOS BANCARIOS Y FACTURA | PROCESO NORMAL",
          txt: "\nEn seguimiento a nuestra conversación telefónica, le pedimos posterior a la consulta ingrese a la siguiente liga, adjunte su factura y sus datos bancarios.\n\nLINK: <<POR_GENERAR>>\n\nUna vez que contemos con su factura el pago de la misma lo realizaremos 72 horas hábiles posteriores.\n\nPara visualizar su comprobante de pago y para futuros cobros de honorarios le agradeceremos que ingrese a nuestro portal www.sinergiamedica.info con su usuario (correo electrónico ) y contraseña (RFC).\n\nAtentamente Sinergia Médica ",
        },
        {
          id: 2,
          name: "OPCIÓN 2.1 | CARGAR DATOS BANCARIOS Y FACTURA | PAGO INMEDIATO",
          txt: "\nEn seguimiento a nuestra conversación telefónica, le pedimos posterior a la consulta ingrese a la siguiente liga, adjunte su factura y sus datos bancarios.\n\nLINK: <<POR_GENERAR>>\n\n Una vez que contemos con su factura el pago de la misma lo realizaremos 24 horas antes de su cita, le agradeceremos ingrese su factura a la liga a la brevedad posible.\n\nPara visualizar su comprobante de pago y para futuros cobros de honorarios le agradeceremos que ingrese a nuestro portal www.sinergiamedica.info con su usuario (correo electrónico ) y contraseña (RFC). Reiteramos que el tiempo habitual de pago, una vez realizada la consulta, es de 72 horas hábiles a partir de que se adjunte la factura.\n\nAtentamente Sinergia Médica ",
        },
      ],
      doctor_email: null,
      doctor_email_dlg: false,
      doctor_email_dlg_ldg: false,
    };
  },
  methods: {
    getDataTable() {
      this.loading = true;

      Axios.get(
        URL_API + `/rss/${this.$route.params.id}/3`,
        headersToken(this.login.token)
      ).then((res) => {
        this.discharged = res.data.data.discharged;
        this.rs_type_id = res.data.data.rs_type_id;
        this.rs_services = res.data.data.rs_services;
        this.rs_services_medical = res.data.data.rs_services_medical;

        this.medical_revision =
          res.data.data.accident !== null && res.data.data.initial !== null
            ? true
            : false;

        this.loading = false;
      });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    formView(v) {
      this.data = v;
      this.view_dialog = true;
    },
    formViewClose() {
      this.view_dialog = false;
      this.letter_generated = false;
    },
    benefitRevisionView(v) {
      this.benefit_revision = v;
      this.benefit_revision.documents = [];
      this.benefit_revision_dialog = true;
    },
    benefitRevisionViewClose() {
      this.benefit_revision = null;
      this.benefit_revision_dialog = false;
      this.benefit_revision_dialog_loading = false;
    },
    benefitRevision(revision) {
      if (this.benefit_revision.documents.length > 0) {
        if (this.$refs.form_submit_revision.validate()) {
          this.$swal
            .fire(
              msgConfirm(
                `¿Confirma ${revision ? "VALIDAR" : "RECHAZAR"} la prestación?`
              )
            )
            .then((res) => {
              if (res.isConfirmed) {
                this.loading = true;

                let obj = {
                  rs_service_benefit_id: this.benefit_revision.id,
                  revision_by_id: this.login.id,
                  response_revision: revision ? true : false,
                  documents: this.benefit_revision.documents,
                };

                storeUpdate(
                  "rss/benefits/revision",
                  this.login.token,
                  null,
                  obj,
                  this.login.id
                ).then((res) => {
                  this.$swal.fire(
                    msgAlert(res.success ? "success" : "error", res.message)
                  );

                  if (res.success) {
                    this.benefitRevisionViewClose();
                    this.getDataTable();
                  }
                });
              }
            });
        } else {
          this.$swal.fire(
            msgAlert(
              "error",
              "Formulario invalido, revisa los detalles señalados"
            )
          );
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Se requiere la carga de al menos 1 documento para la revisión"
          )
        );
      }
    },
    serviceRefused() {
      this.$swal
        .fire(msgConfirm("¿Confirma cancelar el servicio?"))
        .then((res) => {
          if (res.isConfirmed) {
            this.loading = true;

            let obj = {
              rs_service_id: this.data.id,
              updated_by_id: this.login.id,
            };

            Axios.post(
              URL_API + "/rss/services/revision",
              obj,
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.getDataTable();
              }
            });
          }
        });
    },
    consultationConfirmDialog() {
      this.consultation_confirm_dialog = true;
      this.consultation_confirm_dialog_loading = false;
      this.confirm_consultation_name = null;
      this.confirm_consultation_date = null;
      this.confirm_consultation_time = null;
      this.confirm_consultation_amount = null;
    },
    consultationConfirm(response_confirm_consultation) {
      if (this.$refs.form_consultation_confirm.validate()) {
        this.$swal.fire(msgConfirm(`¿Confirma la consulta?`)).then((res) => {
          if (res.isConfirmed) {
            this.consultation_confirm_dialog_loading = true;

            Axios.post(
              URL_API + "/rss/confirm/consultation",
              {
                rs_service_id: this.data.id,
                confirm_consultation_by_id: this.login.id,
                confirm_consultation_name: this.confirm_consultation_name,
                confirm_consultation_date:
                  this.confirm_consultation_date +
                  " " +
                  this.confirm_consultation_time,
                confirm_consultation_amount: this.confirm_consultation_amount,
                response_confirm_consultation: response_confirm_consultation,
              },
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.consultation_confirm_dialog = false;
                this.view_dialog = false;
                this.getDataTable();
              }
            });
          }
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    defaultData() {
      this.data = {
        id: null,
        comment: "",
        created_by_id: this.login.id,
        updated_by_id: this.login.id,
        rs_id: this.$attrs.id,
      };
    },
    letterGenerateView(v) {
      if (v.rs_service_type_id != 3) {
        Axios.post(
          URL_API + "/rss/contractor/values",
          {
            rs_service_id: v.id,
          },
          headersToken(this.login.token)
        ).then((res) => {
          v.deducible_amount = res.data.data.deducible_amount;
          v.coinsurance = res.data.data.coinsurance;
          v.coinsurance_amount = res.data.data.coinsurance_amount;
          v.assured_amount =
            v.rs_service_type_id === 1
              ? res.data.data.letter_generated
                ? res.data.data.assured_amount
                : v.doctor.consultation_amount
              : res.data.data.assured_amount;
          v.pharmacy = res.data.data.pharmacy;
          v.caption = res.data.data.caption;

          if (v.rs_service_type_id == 2 && v.provider_type_id == 2) {
            v.caption += "\n\n" + res.data.data.detail;
          }

          v.letter_complete =
            v.rs_service_type_id == 1 ? false : v.letter_complete;

          v.letter_generated_date = dateTimeNow().substring(0, 10);
          v.letter_generated_time = dateTimeNow().substring(11, 19);

          this.letter_generated = true;
          this.formView(v);
        });
      } else {
        this.$router.push({
          name: "servicios.reembolso_seg_medico",
          params: { id: v.rs_id },
        });
      }
    },
    letterGenerate(v) {
      if (
        this.login.permissions.rs_letters.create ||
        v.assured_amount <= v.benefits_amount
      ) {
        this.$swal
          .fire(
            msgConfirm(
              "¿Confirma la generación de la carta? " +
                (this.rs.email != null && this.rs.email != ""
                  ? "Se enviara por correo a " +
                    this.rs.email +
                    (this.rs.email2 != null && this.rs.email2 != ""
                      ? ", al igual que a " + this.rs.email2
                      : "")
                  : "Sin correos registrados para envio")
            )
          )
          .then((res) => {
            if (res.isConfirmed) {
              this.view_dialog_loading = true;

              Axios.post(
                URL_API + "/rss/letters/generate",
                {
                  rs_service_id: v.id,
                  letter_generated_by_id: this.login.id,
                  deducible_amount: v.deducible_amount,
                  coinsurance: v.coinsurance,
                  coinsurance_amount: v.coinsurance_amount,
                  assured_amount: v.assured_amount,
                  caption: v.caption,
                  pharmacy: v.pharmacy,
                  letter_complete: v.letter_complete,
                  link:
                    window.location.origin +
                    "/confirmacion_servicio/" +
                    btoa(v.id),
                  letter_generated_date:
                    this.login.role_id == 1 ? v.letter_generated_date : null,
                  letter_generated_time:
                    this.login.role_id == 1 ? v.letter_generated_time : null,
                },
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.formViewClose();
                  this.letterView(v.id);
                  this.getDataTable();
                }

                this.view_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            `El tope máximo no puede superar al monto de ${this.numberFormat(
              v.benefits_amount
            )}`
          )
        );
      }
    },
    sendForEmail(v) {
      this.$swal
        .fire(
          msgConfirm(
            "¿Confirma re-enviar la carta por correo electrónico? " +
              "Se enviara a " +
              this.rs.email +
              (this.rs.email2 != null && this.rs.email2 != ""
                ? ", al igual que a " + this.rs.email2
                : "")
          )
        )
        .then((rsp) => {
          if (rsp.isConfirmed) {
            this.loading = true;

            Axios.post(
              URL_API + "/rss/services/send_for_email",
              {
                id: v.id,
                link:
                  window.location.origin +
                  "/confirmacion_servicio/" +
                  btoa(v.id),
              },
              headersToken(this.login.token)
            ).then((rsp) => {
              this.$swal.fire(
                msgAlert(
                  rsp.data.success ? "success" : "error",
                  rsp.data.message
                )
              );

              this.loading = false;
            });
          }
        });
    },
    letterView(rs_service_id) {
      window
        .open(this.url_pdf + "/carta/servicios/" + rs_service_id, "_blank")
        .focus();
    },
    RefoundLetterView(rs_id) {
      window
        .open(this.url_pdf + "/rs/refound/bills/" + rs_id, "_blank")
        .focus();
    },
    serviceTypes() {
      this.provider_types_loading = true;
      this.provider_types = [];
      this.service_types = [];

      index("provider/types", this.login.token, []).then((res) => {
        this.provider_types = res.data;

        if (this.rs && this.rs.insured_id) {
          this.service_types.push({
            id: 1,
            name: "CONSULTA",
            item_disabled:
              this.rs.insured.contractor.consultation == 1 ? false : true,
          });

          index(
            "contractors/" + this.rs.insured.contractor_id + "/tipos",
            this.login.token,
            []
          ).then((res) => {
            const contractor_provider_types = res.data;

            this.service_types.push({
              id: 2,
              name: "PROVEEDOR",
              item_disabled:
                contractor_provider_types.length > 0 ? false : true,
            });

            for (let provider_type of this.provider_types) {
              let provider_type_exist = contractor_provider_types.filter(
                (i) => i.id == provider_type.id
              );

              provider_type.item_disabled =
                provider_type_exist.length === 0 ? true : false;
            }

            this.$forceUpdate();
          });
        } else {
          this.service_types = [
            {
              id: 1,
              name: "CONSULTA",
              item_disabled: false,
            },
            {
              id: 2,
              name: "PROVEEDOR",
              item_disabled: false,
            },
          ];

          this.$forceUpdate();
        }

        this.provider_types_loading = false;
      });
    },
    getMunicipalities() {
      this.municipalities_loading = true;

      index(
        "municipalities/" + this.service_search.entity_id,
        this.login.token,
        []
      ).then((res) => {
        this.municipalities = res.data;

        this.municipalities.push({
          id: null,
          municipality: "TODOS",
        });

        this.service_search.municipality_id = null;
        this.municipalities_loading = false;
      });
    },
    searcherDialogView(agreement) {
      this.service_search = {
        type: this.data.rs_service_type_id,
        specialty_type_id: this.data.specialty_type_id,
        provider_type_id: this.data.provider_type_id,
        entity_id: this.data.entity_id,
        municipality_id: this.data.municipality_id,
        zip: this.data.zip,
      };

      this.services = [];
      this.searcher_agreement = agreement;
      this.searcher_dialog = true;

      this.getMunicipalities();
    },
    searcherDialogViewClose() {
      this.searcher_agreement = false;
      this.searcher_dialog = false;
      this.service_search = null;
    },
    serviceSearch() {
      this.services = [];
      this.services_loading = true;
      this.searcher_dialog_loading = true;

      index("service_search", this.login.token, [
        {
          name: "type",
          value: this.service_search.type,
        },
        {
          name: "specialty_type_id",
          value: this.service_search.specialty_type_id,
        },
        {
          name: "provider_type_id",
          value: this.service_search.provider_type_id,
        },
        {
          name: "entity_id",
          value: this.service_search.entity_id,
        },
        {
          name: "municipality_id",
          value: this.service_search.municipality_id,
        },
        {
          name: "zip",
          value: this.service_search.zip,
        },
      ]).then((res) => {
        this.services = res.data;
        this.services_loading = false;
        this.searcher_dialog_loading = false;
      });
    },
    serviceData(v) {
      this.$swal
        .fire(
          msgConfirm(
            `¿Confirma asignar ${
              this.service_search.type === 1 ? "CONSULTA" : "PROVEEDOR"
            }?`
          )
        )
        .then((res) => {
          if (res.isConfirmed) {
            let service_exist = false;

            service_exist = this.rs.services.filter((i) =>
              this.service_search.type == 1
                ? i.doctor_id == v.id
                : i.provider_id == v.id
            );

            service_exist = service_exist.length === 0 ? false : true;

            if (!service_exist) {
              this.serviceCreate(v);
            } else {
              this.$swal
                .fire(
                  msgConfirm(
                    "Este servicio ya ha sido agregado ¿Confirma agregarlo?"
                  )
                )
                .then((res) => {
                  if (res.isConfirmed) {
                    this.serviceCreate(v);
                  }
                });
            }
          }
        });
    },
    serviceCreate(v) {
      this.services_loading = true;
      this.searcher_dialog_loading = true;

      let service = {
        id: this.searcher_agreement ? this.data.id : null,
        active: true,
        type: this.service_search.type,
        specialty_type_id: this.service_search.specialty_type_id,
        provider_type_id: this.service_search.provider_type_id,
        entity_id: this.service_search.entity_id,
        municipality_id: this.service_search.municipality_id,
        zip: this.service_search.zip,
        doctor_id: null,
        doctor: null,
        consulting_room_id: null,
        provider_id: null,
        agreement: 0,
        confirm_consultation:
          v && v.confirm_consultation ? v.confirm_consultation : 0,
        medical_revision: this.medical_revision,
        benefits: [],
        updated_by_id: this.login.id,
        rs_id: this.rs_id,
      };

      if (service.type === 1) {
        service.doctor_id = v.id;
        service.consulting_room_id = v.consulting_room_id;
        this.doctorData(service);
      } else {
        service.provider_id = v.id;
        this.serviceAdd(service);
      }
    },
    doctorData(service) {
      show("doctors", this.login.token, service.doctor_id).then((res) => {
        service.doctor = res;

        service.benefits.push({
          id: null,
          active: true,
          code: null,
          description:
            `${service.doctor.name} ${service.doctor.first_surname} ${service.doctor.second_surname}`.trim(),
          coverage_type: null,
          revision: false,
          revision_by_id: null,
          revision_at: null,
          amount: service.doctor.consultation_amount,
          amount_original: service.doctor.consultation_amount,
          benefit_detail_id: null,
          coverage_type_id: null,
        });

        this.serviceAdd(service);
      });
    },
    serviceAdd(service) {
      storeUpdate(
        "rss/services",
        this.login.token,
        this.searcher_agreement ? service.id : null,
        service,
        this.login.id
      ).then((res) => {
        if (this.searcher_agreement) {
          this.$swal.fire(
            msgAlert(res.success ? "success" : "error", res.message)
          );
        }

        if (res.success) {
          if (!this.searcher_agreement) {
            Axios.post(
              URL_API + "/rss/services/revision",
              {
                rs_service_id: this.data.id,
                updated_by_id: this.login.id,
              },
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.serviceAddFinish();
              }
            });
          } else {
            this.serviceAddFinish();
          }
        }
      });
    },
    serviceAddFinish() {
      this.searcher_agreement = false;
      this.searcher_dialog = false;
      this.services_loading = false;
      this.searcher_dialog_loading = false;
      this.formViewClose();
      this.getDataTable();
    },
    fileAdd() {
      this.benefit_revision.documents.push({
        id: null,
        active: true,
        description: "",
        file: null,
      });
      this.$forceUpdate();
    },
    fileDelete(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el documento (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.benefit_revision.documents[i].id === null) {
              this.benefit_revision.documents.splice(i, 1);
            } else {
              this.benefit_revision.documents[i].active = false;
            }
            this.$forceUpdate();
          }
        });
    },
    isEmptyObject(obj) {
      var name;
      for (name in obj) {
        if (obj.hasOwnProperty(name)) {
          return false;
        }
      }
      return true;
    },
    rsServiceletterGeneratedCancelled(v) {
      this.$swal
        .fire(msgConfirm(`¿Confirma Eliminar carta ${v.folio}?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            Axios.post(
              URL_API + "/rss/services/letter/generated/cancelled",
              {
                id: v.id,
                folio: v.folio,
              },
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.getDataTable();
              }
            });
          }
        });
    },
    copy() {
      var codigoACopiar = document.getElementById("link");
      var seleccion = document.createRange();
      seleccion.selectNodeContents(codigoACopiar);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(seleccion);
      var res = document.execCommand("copy");
      window.getSelection().removeRange(seleccion);

      this.$swal.fire(msgAlert("success", "URL copiado en portapapeles"));
    },
    confirmDlg(item) {
      this.confirm = {
        link:
          window.location.origin + "/confirmacion_servicio/" + btoa(item.id),
        id: item.id,
      };
      this.confirm_dlg_ldg = true;
      this.confirm_dlg = true;

      Axios.get(
        URL_API + "/rss/services/" + item.rs_service_id + "/rha",
        headersToken(this.login.token)
      ).then((res) => {
        this.rha = res.data.data;
        this.confirm_dlg_ldg = false;
      });
    },
    confirmRemove(item) {
      this.$swal
        .fire(msgConfirm(`¿Eliminar confirmación del servicio?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.loading = true;

            Axios.post(
              `${URL_API}/rss/services/confirm_remove`,
              {
                id: item.id,
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.getDataTable();
                this.confirm_dlg = false;
              }

              this.loading = false;
            });
          }
        });
    },
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
    rsServicesPendingDlg(items, type, doctor_id, doctor_name) {
      this.rs_services_pending = items;
      this.rs_services_pending_type = type;
      this.rs_services_pending_doctor_id = doctor_id;
      this.rs_services_pending_doctor_name = doctor_name;
      this.rs_services_pending_dlg = true;
    },
    doctorEmailDlg(item) {
      this.doctor_email = {
        doctor_email_type_id: null,
        email: item.doctor.email,
        txt: "",
        rs_service_id: item.id,
        item: item,
      };
      this.doctor_email_dlg_ldg = false;
      this.doctor_email_dlg = true;
    },
    getDoctorEmailTxt() {
      this.doctor_email.txt =
        "Folio: " +
        this.doctor_email.item.folio +
        "\n" +
        "Paciente: " +
        this.rs.full_name +
        "\n\n" +
        "Estimado Dr. " +
        this.doctor_email.item.doctor.full_name +
        "\n" +
        this.doctor_email_types[this.doctor_email.doctor_email_type_id - 1].txt;
    },
    doctorEmail() {
      if (this.$refs.doctor_email_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma enviar el correo electrónico?"))
          .then((rsp) => {
            if (rsp.isConfirmed) {
              this.doctor_email_dlg_ldg = true;

              Axios.post(
                URL_API + "/rss/services/doctor_email",
                this.doctor_email,
                headersToken(this.login.token)
              ).then((rsp) => {
                this.$swal.fire(
                  msgAlert(
                    rsp.data.success ? "success" : "error",
                    rsp.data.message
                  )
                );

                if (rsp.data.success) {
                  this.doctor_email_dlg = false;
                  this.getDataTable();
                }

                this.doctor_email_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(msgAlert("error", "Revisa los detalles señalados"));
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      URL_API + `/rss/${this.rs_id}/3`,
      headersToken(this.login.token)
    ).then((res) => {
      this.rs = res.data.data;
    });

    catalog("entities", this.login.token).then((res) => {
      this.entities = res;
      this.entities_loading = false;
    });

    index("specialty/types", this.login.token, []).then((res) => {
      this.specialty_types = res.data;
      this.specialty_types_loading = false;
    });

    // this.initData();
    this.getDataTable();
    this.serviceTypes();
  },
};
</script>